import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { DebtorsPageIPN } from './DebtorsByIpn';
import { DebtorsPageName } from './DebtorsByName';
import { useState } from 'react';
import { useAuth } from '../context/AuthContext';

export const DebtorsPage = () => {
  const [value, setValue] = useState(0);
  const auth = useAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='page-wrapper'>
      <div className='logout'
        onClick={() => {
          auth.logoutUser();
        }}>Вийти</div>
      <Tabs
        value={value}
        onChange={handleChange}
      >
        <Tab label="За ІПН" />
        <Tab label="За ПІБ" />
      </Tabs>
      {value === 0 && <DebtorsPageIPN />}
      {value === 1 && <DebtorsPageName />}
    </div>
  );
}