import Cookies from 'js-cookie';
import axios from 'axios';
import { postRefreshToken } from '../api/DebtorsApi';

export const setTokens = (accessToken, refreshToken) => {
  setCookie('access_token', accessToken, {
    secure: false,
    'max-age': 5 * 60 * 1000,
  }); // 5 minutes
  setCookie('refresh_token', refreshToken, {
    secure: false,
    'max-age': 1440 * 7 * 60 * 1000,
  }); // 7 days
};

export const refreshToken = (callback) => {
  const data = {
    refresh: getCookie('refresh_token'),
  };
  postRefreshToken(data)
    .then((res) => {
      if (res.status !== 200) {
        console.log('Виникла проблема авторизації.');
        removeCookie('access_token');
        removeCookie('refresh_token');
        window.location = '/login';
        return;
      }
      setTokens(res.data.access, res.data.refresh);
      setHeaders();
      callback();
    })
    .catch((err) => {
      console.log(err);
      console.log('Виникла проблема авторизації.');
      window.location = '/login';
      removeCookie('access_token');
      removeCookie('refresh_token');
    });
};

export const setAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (request) => {
      request.headers.Authorization = getHeaders();
      return request;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        refreshToken();
      }
      return Promise.reject(error);
    },
  );
};

export const setCookie = (name, value) => {
  Cookies.set(name, value, { expires: 14 });
};

export const getCookie = (name) => Cookies.get(name);

export const removeCookie = (name) => {
  Cookies.remove(name);
};

export const setHeaders = () => {
  axios.defaults.headers.common.Authorization = getHeaders();
};

export const getHeaders = () => {
  const token = getCookie('access_token');
  if (!token) return false;
  return `Bearer ${token}`;
};
