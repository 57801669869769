
import * as React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoginPage } from './pages/Login';
import { DebtorsPage } from './pages/DebtorsPage';
import ProtectedRoute from './utils/ProtectedRoute';

export const App = () => (
  <Router>
    <Routes>
      <Route exact path="/login" element={<LoginPage />} />
      <Route path="*" element={<h1>Page not found</h1>} />
      <Route element={(
        <ProtectedRoute />
      )}
      >
        <Route exact path="/" element={<DebtorsPage />} />
      </Route>
    </Routes>
  </Router>
);