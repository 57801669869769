import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';

export const LoginPage = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!login || !password) {
      toast.error('Please fill all fields');
      return;
    }
    const userData = {
      email: login,
      password: password,
    };
    auth.login(userData)
      .then(() => {
        navigate('/');
      })
      .catch((err) => {
        toast.error('Error. Please try again.');
        console.log(err);
      });
  };

  return (
    <div className="login">
      <div className="form">
      <span>Debtors</span>
        <TextField
          id="login"
          label="Email"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <TextField
          id="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => handleLogin(e)}>Login</Button>
      </div>
      <ToastContainer />
    </div>
  );
}