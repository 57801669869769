import {
  React,
  createContext,
  useState,
  useContext,
  useMemo,
} from 'react';
import {
  setAxiosInterceptors,
  setTokens,
  getCookie,
  removeCookie
} from '../utils/Helper';
import { loginUser } from '../api/DebtorsApi';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [userCookies, setUserCookies] = useState(getCookie('access_token') || '');

  const login = (userData) => {
    const dataPromise = loginUser(userData)
      .then((res) => {
        setTokens(res.access, res.refresh);
        setUserCookies(res.access);
        setAxiosInterceptors();
        return res;
      })
    return dataPromise;
  };

  const logoutUser = async () => {
    removeCookie('access_token');
    removeCookie('refresh_token');
    setUserCookies(null);
  };

  const authValue = useMemo(() => (
    { userCookies, login, logoutUser }), [userCookies, login, logoutUser]);

  return (
    <AuthContext.Provider value={authValue}>
      {children}
    </AuthContext.Provider>
  );
};
