import { Button, TextField } from "@mui/material"
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { ThreeDots } from "react-loader-spinner";
import { postNewPIBGroup } from "../api/DebtorsApi";
import { ListGroups } from "../components/ListGroups";

export const DebtorsPageName = () => {
  const [names, setNames] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [isBtnDisabled, setBtnDisable] = useState(false);
  const [loader, setLoader] = useState(false);

  const sendRequest = () => {
    if (!names && !names?.length) {
      toast.warning('Введіть ПІБ');
      return;
  }
  if (!groupName) {
      toast.warning('Введіть назву групи');
      return;
  }

  const newGroupBody = {
      'name': groupName,
      'pd_data': names.split('\n').filter(item => item.trim() !== '')
  }
  setLoader(true);
  postNewPIBGroup(JSON.stringify(newGroupBody))
      .then(res => {
          if (res.status === 400) {
              toast.error('Помилка при створенні')
              setLoader(false);
              return;
          }
          toast.success('Група створена')
          setBtnDisable(false)
          setLoader(false);
      })
      .catch(err => {
          console.log(err)
          toast.error('Помилка при створенні')
          setLoader(false);
          setBtnDisable(false)
      })

  }

  return (
    <div className="content">
      <div className="block">
      <span>Записано {names?.split('\n').filter(item => item.trim() !== '').length || 0} ПІБ</span>
        <TextField
          id="pib"
          multiline
          minRows={10}
          label="Список ПІБ"
          value={names}
          onChange={(e) => {
            setNames(e.target.value);
          }}
        />
        <TextField
          id="name-group"
          label="Назва групи"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <Button
          disabled={isBtnDisabled}
          variant="outlined"
          color="primary"
          onClick={() => {
            sendRequest();
          }}>
          Зробити запит
        </Button>
      </div>
      <div className="block">
        <ListGroups
          type="pib"
          setLoader={setLoader}
        />
      </div>
      <ToastContainer />
      {loader && <div className="loader">
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#2191ff"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>}
    </div>
  )
}