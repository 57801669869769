import { useEffect, useState } from "react"
import { downloadIPNGroupXLSX, downloadPIBGroupXLSX, getIPNGroups, getPIBGroups } from "../api/DebtorsApi"
import { Button } from "@mui/material";
import { ReactComponent as DownloadIcon } from '../download.svg';
import { toast } from "react-toastify";

export const ListGroups = (props) => {
    const { type } = props;
    const [groupsIPNList, setGroupsIPNList] = useState([]);
    const [groupsPIBList, setGroupsPIBList] = useState([]);

    useEffect(() => {
        getIPNGroups()
            .then(res => {
                setGroupsIPNList(res?.groups?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));

            })
            .catch(err => {
                console.log(err);
                setGroupsIPNList([]);
            })
    }, [])

    useEffect(() => {
        getPIBGroups()
            .then(res => {
                setGroupsPIBList(res?.groups?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
            })
            .catch(err => {
                console.log(err);
                setGroupsPIBList([]);
            })
    }, [])

    const handleGroupClick = async (groupId) => {
        if (type === 'ipn') {
            downloadIPNGroupXLSX(groupId)
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Група ${groupId}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(() => {
                    toast.error('Помилка при завантаженні файлу');
                });
        }
        if (type === 'pib') {
            downloadPIBGroupXLSX(groupId)
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Група ${groupId}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(() => {
                    toast.error('Помилка при завантаженні файлу');
                });
        }
    }

    const getGroupDate = (date) => {
        const d = new Date(date);
        return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`
    }

    return (<>
        {type === 'ipn' && groupsIPNList?.map((group, index) => (
            <Button
                key={index}
                className="group-button"
                variant="outlined"
                color="primary"
                title={`Оброблено ${group.ipn_processed}/${group.ipn_count}`}
                onClick={() => handleGroupClick(group.id)}>
                {group.name} ({getGroupDate(group.created_at)})
                <DownloadIcon />
            </Button>
        ))}
        {type === 'ipn' && groupsIPNList?.length === 0 && <span>Групи відсутні</span>}
        {type === 'pib' && groupsPIBList?.map((group, index) => (
            <Button
                key={index}
                className="group-button"
                variant="outlined"
                color="primary"
                title={`Оброблено ${group.pd_data_processed}/${group.pd_data_count}`}
                onClick={() => handleGroupClick(group.id)}>
                {group.name} ({getGroupDate(group.created_at)})
                <DownloadIcon />
            </Button>
        ))}
        {type === 'pib' && groupsPIBList?.length === 0 && <span>Групи відсутні</span>}
    </>)
}