import axios from 'axios';

const baseUrl = 'https://asvp.feodal.online/';

export const getIPNGroups = async () => {
    let url = `${baseUrl}asvp/groups/ipns/`;
    const res = await axios.get(url);
    return res.data;
}

export const getPIBGroups = async () => {
    let url = `${baseUrl}asvp/groups/personal-details/`;
    const res = await axios.get(url);
    return res.data;
}

export const postNewIPNGroup = async (body) => {
    let url = `${baseUrl}asvp/groups/create/by-ipns/`;
    const res = await axios.post(url, body);
    return res.data;
}

export const postNewPIBGroup = async (body) => {
    let url = `${baseUrl}asvp/groups/create/by-personal-details/`;
    const res = await axios.post(url, body);
    return res.data;
}

export const loginUser = async (body) => {
    let url = `${baseUrl}asvp/api/token/`;
    const res = await axios.post(url, body);
    return res.data;
}

export const postRefreshToken = async (body) => {
    let url = `${baseUrl}asvp/api/token/refresh/`;
    const res = await axios.post(url, body);
    return res.data;
}

export const downloadIPNGroupXLSX = async (groupId) => {
    let url = `${baseUrl}asvp/groups/${groupId}/export/ipns/xlsx/`;
    const res = await axios.get(url, { responseType: 'blob' });
    return res.data;
}

export const downloadPIBGroupXLSX = async (groupId) => {
    let url = `${baseUrl}asvp/groups/${groupId}/export/personal-details/xlsx/`;
    const res = await axios.get(url, { responseType: 'blob' });
    return res.data;
}