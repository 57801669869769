import { Button, TextField } from "@mui/material"
import { ListGroups } from "../components/ListGroups"
import { useState } from "react";
import { postNewIPNGroup } from "../api/DebtorsApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeDots } from "react-loader-spinner";

export const DebtorsPageIPN = () => {
    const [ipns, setIpns] = useState(null);
    const [groupName, setGroupName] = useState(null);
    const [isBtnDisabled, setBtnDisable] = useState(false);
    const [loader, setLoader] = useState(false);

    const getTruncatedLines = (ipnsValues) => {
        const lines = ipnsValues.split('\n');
        const truncatedLines = lines?.map(line => line.padStart(10, 0))
            .filter(item => item.trim() !== '0000000000');

        return truncatedLines
    }

    const sendRequest = () => {
        if (!ipns && !ipns?.length) {
            toast.warning('Введіть іпн');
            return;
        }
        if (!groupName) {
            toast.warning('Введіть назву групи');
            return;
        }

        const newGroupBody = {
            'name': groupName,
            'ipns': getTruncatedLines(ipns)
        }
        setLoader(true);
        postNewIPNGroup(JSON.stringify(newGroupBody))
            .then(res => {
                if (res.status === 400) {
                    toast.error('Помилка при створенні')
                    setLoader(false);
                    return;
                }
                toast.success('Група створена')
                setBtnDisable(false)
                setLoader(false);
            })
            .catch(err => {
                console.log(err)
                toast.error('Помилка при створенні')
                setLoader(false);
                setBtnDisable(false)
            })

    }

    return (<div className="content">
        <div className="block">
            <span>Записано {ipns?.split('\n').filter(item => item.trim() !== '').length || 0} іпн</span>
            <TextField
                id="list-ipns"
                multiline
                minRows={10}
                label="Список ІПН"
                value={ipns}
                onChange={(e) => {
                    setIpns(e.target.value);
                }}
            />
            <TextField
                id="name-group"
                label="Назва групи"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
            />
            <Button
                disabled={isBtnDisabled}
                variant="outlined"
                color="primary"
                onClick={() => {
                    sendRequest();
                }}>
                Зробити запит
            </Button>
        </div>
        <div className="block">
            <ListGroups 
                type='ipn'
                setLoader={setLoader}
            />
        </div>
        <ToastContainer />
        {loader && <div className="loader">
            <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#2191ff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>}
    </div>)
}